@font-face {
  font-family: 'PFDINTextPro-Thin';
  src: url("../assets/webfonts/3B10B1_0_0.eot");
  src: url("../assets/webfonts/3B10B1_0_0.eot?#iefix") format("embedded-opentype"), url("../assets/webfonts/3B10B1_0_0.woff2") format("woff2"), url("../assets/webfonts/3B10B1_0_0.woff") format("woff"), url("../assets/webfonts/3B10B1_0_0.ttf") format("truetype"); }

@font-face {
  font-family: 'PFDinTextCompPro-Medium';
  src: url("../assets/webfonts/3B10B1_1_0.eot");
  src: url("../assets/webfonts/3B10B1_1_0.eot?#iefix") format("embedded-opentype"), url("../assets/webfonts/3B10B1_1_0.woff2") format("woff2"), url("../assets/webfonts/3B10B1_1_0.woff") format("woff"), url("../assets/webfonts/3B10B1_1_0.ttf") format("truetype"); }


:root {
	font-family: 'PFDinTextCompPro-Medium', 'PFDINTextPro-Thin';
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color-scheme: light dark;
	color: #444;
	background: #fafafa;
}

:root {
		color: #fff;
		background: #1c1c1c;
}


body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

#app > main {
	display: flex;
	padding-top: 3.5rem;
	margin: 0 auto;
	min-height: calc(100vh - 3.5rem);
	max-width: 1280px;
	align-items: center;
	justify-content: center;
}

@media (max-width: 639px) {
	#app > main {
		margin: 0 2rem;
	}
}
