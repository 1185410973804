.header {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center; /* Vertically align items */
	width: 100%;
	height: 3.5rem;
	//background: rgb(255, 221, 18);
	background: black;

	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
  }

  .header a {
	display: inline-block;
	padding: 0 1rem;
	color: white;
	text-decoration: none;
	line-height: 3.5rem;
  }

  .header a:hover{
	background: #ffdc00;
	color: black;
	box-shadow: 0 0px 5px 0px  #000000;
  }

  .header a.active {
	background: rgba(255, 255, 255, 0.8);
	color: black;
	box-shadow: 0 0px 5px 0px  #000000;
  }

  .header .active:hover{
	background: #ffdc00;
	box-shadow: 0 0px 5px 0px  #000000;
  }

  .header a.logo {
	display: flex;
	align-items: center;
	height: 56px;
  }

  .logo h1 {
	padding: 0 0.5rem;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
  }
  .menuToggle {
	display: none;
  }
  @media (max-width: 1430px) {
	.logo h1 {
	  display: none;
	}

	/* Show hamburger menu icon */
	.menuToggle {
	  display: none;
	  cursor: pointer;
	  width: 30px;
	  height: 30px;
	  background-color: black;
	  border: none;
	  position: relative;
	  top: 15px;
	  padding: 15px; /* Increase padding for better clickability */
	  z-index: 1; /* Ensure it's above other content */
	}

	.menuIcon {
	  cursor: pointer;
	  width: 30px;
	  height: 30px;
	  background-color: transparent;
	  border: none;
	  position: relative;
	  top: 0px;
	  padding: 15px; /* Increase padding for better clickability */
	  z-index: 1; /* Ensure it's above other content */
	}

.menuIcon:hover {
	background-color: rgba(255, 221, 18, 0.8);
}

	.menuIcon .bar1, .menuIcon .bar2, .menuIcon .bar3 {
		width: 30px;
		height: 5px;
		background-color: #333;
		margin: 4px 0;
		transition: 0.4s;
	  }


	/* When the menuToggle input is checked, show the menuIcon bars */
	.menuToggle:checked + .menuIcon .bar1 {
	  transform: translate(0, 9px) rotate(45deg);
	}

	.menuToggle:checked + .menuIcon .bar2 {
	  opacity: 0;
	}

	.menuToggle:checked + .menuIcon .bar3 {
	  transform: translate(0, -9px) rotate(-45deg);
	}

	/* Styles for the navigation menu */
	/* ... Previous styles ... */

/* Styles for the navigation menu */
.nav {
	display: flex;
	flex-direction: column;
	background-color: black;
	color: white;
	position: absolute;
	top: 3.5rem;
	left: 0;
	width: 100%;
	max-height: 0; /* Initially hide the menu */
	overflow: hidden; /* Hide the content that overflows the max-height */
	transition: max-height 0.4s ease; /* Add transition for sliding effect */
  }

  .nav a {
	padding: 1rem;
	font-size: 1.2rem;
	text-align: center;
  }

  /* Styles for the navigation menu when hamburger is checked */
  .menuToggle:checked + .menuIcon + .nav {
	max-height: 1000px; /* Set a large enough value to reveal the menu */
  }

  /* ... Previous styles ... */



  }
