/* Global styles */
.login, .home {
	text-align: center;
	position: relative;
}

.backgroundVideo{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	opacity: 0.4;
}

.login img, .home img {
	padding: 1.5rem;
}

.login img:hover, .home img:hover {
	filter: drop-shadow(0 0 3rem #fbd603);
}

.home section {
    margin-top: 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    justify-content: center;
    width: 100%; /* Ensure the section takes full width */
}
.login section {
	margin-top: 10rem;
	display: grid;
	column-gap: 1.5rem;
	justify-content: center;
	width: 100%; /* Ensure the section takes full width */
}
/* Centering content inside grid items */
.login section > *, .home section > * {
    text-align: center;
}

@media (max-width: 639px) {
	.login section, .home section {
		margin-top: 5rem;
		grid-template-columns: 1fr;
		row-gap: 1rem;
	}
}

.resource {
	padding: 0.75rem 1.5rem;
	text-align: left;
	text-decoration: none;
	color: white;
	background-color: black;
	border: 3px solid white;
}

.resource:hover {
	color: black;
	background-color: white;
}

/* Input and Select Styles */
input, select {
    padding: 10px 20px;
    border: 2px solid white;/*solid #ffd700;*/
    font-size: 18px;
    margin-bottom: 20px;
    color: white;
		background-color: black;
    outline: none;
}

input {
    width: 300px; /* Adjust width as needed */
}

select {
    width: 150px; /* Adjust width as needed */
}

input:focus, select:focus {
    background-color: white;
    color: black; /*#ffd700;*/
}

input:hover, select:hover {
    /*box-shadow: 0 10px 50px -12px #fbb103;*/
		background-color: rgba(255, 255, 255, 0.8);/*rgba(0, 0, 0, 0.5);*/
		color: black;
}

button {
	background-color: black;
	color: white;
	text-decoration: none;
	border: 3px solid white;
	padding: 10px 20px;
	font-size: 20px;
}

button:hover {
	background-color: white;
	color: black;
}
/* Button Styles */
button:disabled {
    background-color: #ffd9005c;
    color: rgba(58, 57, 57, 0.359);
    cursor: not-allowed;
}

button:disabled:hover {
    background-color: #8d7f319a;
    color: rgba(90, 89, 89, 0.359);
}


/* Loading Styles */
.loading {
    text-align: center;
    margin-top: 20px;
}

.loading p {
    font-size: 16px;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid rgb(255, 213, 54);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    margin-top: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
